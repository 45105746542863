import { defineStore } from "pinia";
import { ref, watch } from "vue";

import api from "@/lib/api/internal-base";
import { useNewsletter } from "@/lib/newsletter";
import offlineSearch from "@/search/executor";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { SearchResult } from "@/types/search_result";

export const useStore = defineStore(
  "search",
  () => {
    const scaffoldingStore = useScaffoldingStore();
    const newsletter = useNewsletter();
    const pending = ref(false);
    const value = ref("");
    const results = ref<SearchResult[]>([]);

    watch(value, async (updatedValue) => {
      pending.value = true;
      if (updatedValue === "") {
        results.value = [];
      } else {
        const offlineSearchResults = offlineSearch(
          updatedValue,
          scaffoldingStore.currentPermissions,
          newsletter.newsletter.value
        );
        results.value = offlineSearchResults;
        const response = await api.path("/search").method("post").create()({
          text: value.value,
        });
        const onlineSearchResults = response.data
          .results as any as SearchResult[];
        results.value = [...offlineSearchResults, ...onlineSearchResults];
      }
      pending.value = false;
    });

    return {
      pending,
      value,
      results,
    };
  },
  {
    broadcast: {
      omit: ["value"],
    },
  }
);
