import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import { safeLocalStorageGetItem } from "@/store/local_storage";

const extractParameters = (
  querystring: string,
  keys: readonly string[],
  unstructuredKeys: readonly string[]
): Parameter<string> => {
  let state: Parameter<string> = {};
  const params = new URLSearchParams(querystring);
  keys.map((key) => {
    const value = params.getAll(key + "[]");
    state[key] = value;
  });

  // Next, we handle unstructured keys. This looks a little something like:
  // `/subscribers?survey['foo'][]=bar`
  // Which we then want to translate into {'survey['foo']': ['bar']}
  [...params.entries()].forEach(([key]) => {
    unstructuredKeys.map((unstructuredKey) => {
      if (key.startsWith(unstructuredKey + "['")) {
        state[key.replace("[]", "")] = params.getAll(key);
      }
    });
  });
  return state;
};

export const constructInitialParameters = (
  pathname: string,
  localStorageKey: string,
  parameterKeys: readonly string[],
  unstructuredKeys: readonly string[]
): Parameter<string> => {
  if (window.location.pathname === pathname) {
    return extractParameters(
      window.location.search,
      parameterKeys,
      unstructuredKeys
    );
  }
  const localParameters = safeLocalStorageGetItem(localStorageKey);
  if (localParameters) {
    return JSON.parse(localParameters);
  }
  return {};
};
