<template>
  <Template.Define>
    <div class="absolute" :class="{ 'right-4': iconLocation === 'right' }">
      <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
      <component :is="iconMappingMicro[icon]" v-else-if="icon" class="size-4" />
      <slot v-else name="icon" />
    </div>

    <div class="flex-1 text-center">
      {{ text }}
      <span v-if="description" class="sr-only">{{ description }}</span>
    </div>

    <div
      v-if="hotkey"
      :class="[
        'text-sm font-mono uppercase rounded-lg px-2 hidden lg:block',
        variant === 'primary' && 'bg-black/50',
        variant === 'secondary' && 'bg-black/15',
      ]"
    >
      {{ formatKeybind(hotkey) }}
    </div>
  </Template.Define>

  <RouterLink v-if="to" ref="elRef" v-bind="$attrs" :class="cls" :to="to">
    <Template.Use />
  </RouterLink>
  <a
    v-else-if="href"
    ref="elRef"
    v-bind="$attrs"
    :class="cls"
    :href="href"
    :target="target"
  >
    <Template.Use />
  </a>
  <button
    v-else
    ref="elRef"
    :class="cls"
    v-bind="$attrs"
    :disabled="disabled"
    :type="type"
  >
    <Template.Use />
  </button>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router/auto";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import {
  type MappedIconMicro,
  iconMappingMicro,
} from "@/icons/icon-mapping-micro";
import { createReusableTemplate } from "@/lib/composables/template";
import { formatKeybind, useKeybinds } from "@/lib/hotkeys";
import { type RouteLocation } from "@/router/types";

const Template = createReusableTemplate();

const router = useRouter();

const elRef = ref();
const props = withDefaults(
  defineProps<{
    to?: RouteLocation;
    href?: string;
    icon?: MappedIconMicro;
    text: string;
    description?: string;
    pending?: boolean;
    variant?: "primary" | "secondary" | "warning" | "negative";
    iconLocation?: "left" | "right";
    hotkey?: string;
    disabled?: boolean;
    highlight?: boolean;
    target?: string;
    type?: "submit" | "button";
  }>(),
  {
    variant: "secondary",
    iconLocation: "left",
    type: "button",
  }
);

const cls = computed(() => {
  const variant = props.variant;
  const base =
    "m-0 relative shadow-sm inline-flex items-center p-2 px-4 text-sm font-medium h-auto no-underline w-full box-border rounded-lg transition-colors";

  if (variant === "primary") {
    return "bg-blue-500 text-white hover:bg-blue-600 " + base;
  }
  if (variant === "negative") {
    return "bg-red-500 text-white hover:bg-red-600 " + base;
  }
  if (variant === "warning") {
    return "bg-red-500 text-white hover:bg-red-600 " + base;
  }

  return (
    "bg-white text-gray-700 border border-solid border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900 " +
    (props.highlight ? "!ring-2 !ring-blue-500 " : "") +
    base
  );
});

useKeybinds(() => {
  const hotkey = props.hotkey;
  if (!hotkey || props.disabled) {
    return;
  }

  return {
    [hotkey](ev) {
      if (ev.defaultPrevented) {
        return;
      }

      if (props.to) {
        router.push(props.to);
      } else if (props.href) {
        window.location.href = props.href;
      } else {
        elRef.value?.click();
      }

      return true;
    },
  };
});
</script>
