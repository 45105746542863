<template>
  <Tooltip.Root :disabled="!$slots.content" :always-show="emphasizeDisablement">
    <Tooltip.Trigger>
      <div ref="nodeEl" class="contents @2xl:hidden">
        <Tooltip.Root :disabled="!!$slots.content">
          <Tooltip.Trigger>
            <RouterLink v-if="to" :to="to" class="no-underline">
              <div
                :class="{
                  'group flex rounded-md items-center w-full px-2 py-2 box-border text-xs space-x-2': true,
                  'bg-gray-600 hover:bg-gray-700 text-white':
                    variant === undefined,
                  'bg-green-600 text-white hover:bg-green-700':
                    variant === 'primary',
                  'bg-red-600 text-white hover:bg-red-700':
                    variant === 'negative',
                  'cursor-disabled': pending || disabled,
                }"
              >
                <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
                <component
                  :is="iconMappingMicro[icon]"
                  v-else-if="icon"
                  class="size-4"
                />

                <div
                  v-if="hotkey !== '' && hotkey !== undefined"
                  class="text-[9px] font-mono uppercase rounded bg-black/50 w-3 text-center px-1 hidden lg:block"
                >
                  {{ hotkey }}
                </div>
              </div>
            </RouterLink>
            <button
              v-else
              :class="{
                'group flex rounded-md items-center w-full px-2 py-2 box-border text-xs space-x-2 cursor-pointer': true,
                'bg-gray-600 hover:bg-gray-700 text-white':
                  variant === undefined,
                'bg-green-600 text-white hover:bg-green-700':
                  variant === 'primary',
                'bg-red-600 text-white hover:bg-red-700':
                  variant === 'negative',
                'cursor-not-allowed opacity-75': pending || disabled,
              }"
              type="button"
              @click="onClick"
            >
              <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
              <component
                :is="iconMappingMicro[icon]"
                v-else-if="icon"
                class="size-4"
              />

              <div
                v-if="hotkey"
                class="text-[9px] font-mono uppercase rounded bg-black/50 w-4 text-center px-1 hidden lg:block"
              >
                {{ formatKeybind(hotkey) }}
              </div>
            </button>
          </Tooltip.Trigger>

          <Tooltip.Content>
            {{ text }}
          </Tooltip.Content>
        </Tooltip.Root>
      </div>

      <div class="hidden @2xl:contents">
        <RouterLink v-if="to" :to="to" class="no-underline">
          <div
            :class="{
              'group flex rounded-md items-center w-full px-2 py-2 box-border text-xs space-x-2': true,
              'bg-gray-600 hover:bg-gray-700 text-white': variant === undefined,
              'bg-green-600 text-white hover:bg-green-700':
                variant === 'primary',
              'bg-red-600 text-white hover:bg-red-700': variant === 'negative',
              'cursor-disabled': pending || disabled,
            }"
          >
            <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
            <component
              :is="iconMappingMicro[icon]"
              v-else-if="icon"
              class="size-4"
            />

            <div class="whitespace-nowrap">{{ text }}</div>
            <div
              v-if="hotkey"
              class="text-[9px] font-mono uppercase rounded bg-black/50 w-4 text-center px-1 hidden lg:block"
            >
              {{ formatKeybind(hotkey) }}
            </div>
          </div>
        </RouterLink>
        <span v-else>
          <button
            ref="button"
            :class="{
              'group flex rounded-md items-center w-full px-2 py-2 box-border text-xs space-x-2 cursor-pointer': true,
              'bg-gray-600 hover:bg-gray-700 text-white': variant === undefined,
              'bg-green-600 text-white hover:bg-green-700':
                variant === 'primary',
              'bg-red-600 text-white hover:bg-red-700': variant === 'negative',
              '!cursor-not-allowed opacity-75': pending || disabled,
              shaking: emphasizeDisablement && disabled,
            }"
            type="button"
            @click="
              (!disabled || allowClickingDespiteDisablement) && onClick?.()
            "
          >
            <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
            <component
              :is="iconMappingMicro[icon]"
              v-else-if="icon"
              class="size-4"
            />

            <div class="whitespace-nowrap">{{ text }}</div>
            <div
              v-if="hotkey"
              class="text-[9px] font-mono uppercase rounded bg-black/50 w-4 text-center px-1"
            >
              {{ formatKeybind(hotkey) }}
            </div>
          </button>
        </span>
      </div>
    </Tooltip.Trigger>

    <Tooltip.Content v-if="$slots.content">
      <slot name="content" />
    </Tooltip.Content>
  </Tooltip.Root>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { RouterLink } from "vue-router/auto";

import Tooltip from "@/design_system/Tooltip";
import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import {
  type MappedIconMicro,
  iconMappingMicro,
} from "@/icons/icon-mapping-micro";
import { formatKeybind, useKeybinds } from "@/lib/hotkeys";
import { RouteLocation } from "@/router/types";

import { NO_IDS_SENTINEL } from "./lib";
import { ExposedSelectionWidgetItem } from "./types";

const router = useRouter();

const props = defineProps<{
  to?: RouteLocation;
  text: string;
  hotkey?: string;
  pending?: boolean;
  disabled?: boolean;
  allowClickingDespiteDisablement?: boolean;
  icon: MappedIconMicro;
  variant?: "primary" | "negative";
  onClick?: () => void;
}>();

const emphasizeDisablement = ref(false);
const nodeEl = ref<HTMLElement>();

defineExpose<ExposedSelectionWidgetItem>({
  get node() {
    return (nodeEl.value?.parentNode || null) as HTMLElement | null;
  },
});

useKeybinds(() => {
  const hotkey = props.hotkey;
  if (!hotkey) {
    return;
  }

  const listener = (ev: KeyboardEvent) => {
    if (ev.defaultPrevented) {
      return;
    }

    if (props.disabled) {
      // We want to ignore the default behavior (so that hitting CMD+S doesn't
      // trigger the save button) but we don't actually want to _do_ the thing.
      // So just return false after setting `shaking` to true.
      emphasizeDisablement.value = true;

      if (!props.allowClickingDespiteDisablement) {
        return;
      }
    }

    if (props.to) {
      // If no rows are selected, don't have the keyboard shortcut do anything.
      if ((props.to as any).params?.ids === NO_IDS_SENTINEL) {
        return;
      }

      router.push(props.to);
    } else {
      props.onClick?.();
    }

    return true;
  };

  // Tiny edge case; if we have a hotkey of `S`, we want Command+S to work, too.
  if (hotkey === "s") {
    return { s: listener, "$mod+s": listener };
  }

  return { [hotkey]: listener };
});
</script>

<style lang="scss">
@keyframes tilt-n-move-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(-9px);
  }
  35% {
    transform: translateY(-9px) rotate(5deg);
  }
  55% {
    transform: translateY(-9px) rotate(-5deg);
  }
  65% {
    transform: translateY(-9px) rotate(5deg);
  }
  75% {
    transform: translateY(-9px) rotate(-5deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.shaking {
  animation: tilt-n-move-shaking 0.5s;
}
</style>
