import { defineStore } from "pinia";

import api from "@/lib/api/base";

import {
  ListResource,
  useCreateListUpdateDeleteResource,
  useCreateListUpdateResource,
  useListResource,
} from "./base";
import {
  compose,
  Lazy,
  updateResourceOnMount,
  updateResourceOnNewsletterChange,
} from "./internal-api-mirror";

const checkForHydratedPayload = <M, O, R extends ListResource<M, O, any>>(
  path: string
): ((resource: R) => R) => {
  return (resource: R) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const hydratedPayload = HYDRATED_PAYLOAD[path];
    if (hydratedPayload) {
      resource.resource.value = hydratedPayload;
    }
    return resource;
  };
};

export const useStore = defineStore(
  "openapi-mirror",
  () => {
    return {
      "/comments": new Lazy(() =>
        compose(
          useListResource(api.path("/comments").method("get").create(), {
            expand: ["subscriber", "email"],
          })
        ).then([updateResourceOnMount, updateResourceOnNewsletterChange])
      ),
      "/external_feeds": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            api.path("/external_feeds").method("get").create(),
            api.path("/external_feeds").method("post").create(),
            api.path("/external_feeds/{id}").method("patch").create(),
            api.path("/external_feeds/{id}").method("delete").create()
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/users": new Lazy(() =>
        compose(
          useCreateListUpdateResource(
            api.path("/users").method("get").create(),
            api.path("/users").method("post").create(),
            api.path("/users/{id}").method("patch").create()
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/webhooks": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            api.path("/webhooks").method("get").create(),
            api.path("/webhooks").method("post").create(),
            api.path("/webhooks/{id}").method("patch").create(),
            api.path("/webhooks/{id}").method("delete").create()
          )
        ).then([
          updateResourceOnNewsletterChange,
          updateResourceOnMount,
          checkForHydratedPayload("/webhooks"),
        ])
      ),
      "/coupons": new Lazy(() =>
        compose(
          useListResource(api.path("/coupons").method("get").create())
        ).then([updateResourceOnNewsletterChange])
      ),
      "/advertising_units": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            api.path("/advertising_units").method("get").create(),
            api.path("/advertising_units").method("post").create(),
            api.path("/advertising_units/{id}").method("patch").create(),
            api.path("/advertising_units/{id}").method("delete").create()
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/tags": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            api.path("/tags").method("get").create(),
            api.path("/tags").method("post").create(),
            api.path("/tags/{id}").method("patch").create(),
            api.path("/tags/{id}").method("delete").create()
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/survey_responses": new Lazy(() =>
        compose(
          useListResource(
            api.path("/survey_responses").method("get").create(),
            {
              expand: ["subscriber", "email", "automation"],
            }
          )
        ).then([updateResourceOnNewsletterChange])
      ),
      "/surveys": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            api.path("/surveys").method("get").create(),
            api.path("/surveys").method("post").create(),
            api.path("/surveys/{id}").method("patch").create(),
            api.path("/surveys/{id}").method("delete").create()
          )
        ).then([
          updateResourceOnNewsletterChange,
          updateResourceOnMount,
          checkForHydratedPayload("/surveys"),
        ])
      ),
    };
  },
  {
    broadcast: {},
  }
);
