<template>
  <Header :breadcrumbs="props.breadcrumbs">
    <template #actions>
      <slot name="actions" />
    </template>
  </Header>
  <slot name="callouts" />
  <PageContent>
    <div
      v-if="pending"
      class="flex absolute top-0 left-0 right-0 bottom-0 items-center z-10"
    >
      <div
        :class="{
          'mx-auto text-2xl text-center space-y-2 text-gray-400': !(
            items && items.length
          ),
          'bg-gray-600 text-white rounded-lg text-sm flex absolute bottom-4 right-4 items-center px-2 py-1 gap-2':
            items && items.length,
        }"
      >
        <ArrowPathIcon
          :class="{
            'size-4 mx-auto animate-spin': true,
            'size-6': !(items && items.length),
          }"
        />
        <div>Loading</div>
      </div>
    </div>
    <div
      v-else-if="$slots.empty"
      class="flex absolute top-0 left-0 right-0 bottom-0 items-center z-10 empty:hidden"
    >
      <slot name="empty" />
    </div>
    <slot name="body" />
  </PageContent>
</template>

<script lang="ts" setup>
import PageContent from "@/components/Layout/PageContent.vue";
import Header, { Props as HeaderProps } from "@/design_system/View/Header.vue";
import ArrowPathIcon from "@/icons/heroicons/arrow-path-outline.svg";

export type Props = HeaderProps & {
  pending?: boolean;
  items?: any[];
};

const props = defineProps<Props>();
</script>
